html,
body {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

app-login {
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
}

app-register {
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
}

app-owner {
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
}

app-cleaner {
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
}

app-welcomer {
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
}

full-calendar {
  width: 50%;
  height: 80%;
}

.fc-event,
.fc-event-title {
  font: 400 10px/12px Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px !important;
}

.mat-button-wrapper {
  width: 100%;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}
